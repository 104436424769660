.sidebar {
  height: 100%;
  width: 320px;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #333;
  overflow-y: scroll;
  z-index: 10;
}

.sidebar:after {
  padding-bottom: 40px;
}

.logo {
  display: block;
  font-family: 'VT323', serif;
  font-size: 38px;
  color: white;
  text-decoration: none;
  text-align: center;
  margin-bottom: 20px;
  padding-top: 10px;
  padding-bottom: 12px;
  border-bottom: 1px solid #555;
}

.logoLeft {
  width: 20px;
  margin-right: 10px;
}

.logoRight {
  width: 20px;
  margin-left: 8px;
}

.content {
  min-height: 100vh;
  margin-left: 320px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #444;
}

.content canvas {
  border: 1px dashed #666;
}

@media only screen and (max-width: 700px) {
  .sidebar {
    height: auto;
    width: 100%;
    position: static;
    margin-bottom: 300px;
  }

  .content {
    min-height: 300px;
    height: 300px;
    width: 100%;
    margin-left: 0;
    position: fixed;
    bottom: 0;
  }
}
