.background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  z-index: 20;
}

.modal {
  position: relative;
  background: #444;
  min-width: 250px;
  width: 40%;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  z-index: 100;
  border-radius: 5px;
  color: white;
  overflow-y: scroll;
}

.header {
  font-size: 1.2em;
  font-weight: bold;
  padding: 24px;
  margin-right: 24px;
  color: #ddd;
}

.closeBtn {
  position: absolute;
  top: 24px;
  right: 24px;
  font-size: 1.4em;
  cursor: pointer;
  color: white;
  background: none;
  border: none;
}

.body {
  padding: 24px;
  margin-bottom: 60px;
  min-height: 100px;
  height: 50%;
  overflow: auto;
}

.footer {
  position: absolute;
  bottom: 24px;
  right: 24px;
}

.footer button {
  margin-left: 12px;
}

@media only screen and (max-width: 400px) {
  .header {
    font-size: 1em;
  }
}