
.slider {
  -webkit-appearance: none;
  appearance: none;
  width: 100%;
  height: 8px;
  outline: none;
  border: none;
  background: #ddd;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 20px;
  height: 20px;
  background: #ef6461;
  cursor: pointer;
  border-radius: 0;
  border: none;
}

.slider::-moz-range-thumb {
  width: 20px;
  height: 20px;
  background: #ef6461;
  cursor: pointer;
  border-radius: 0;
  border: none;
}