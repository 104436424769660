.bg {
  background-image: url('../../assets/images/home-bg.png');
  width: 100%;
  height: 100vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  overflow: hidden;
}

.content {
  position: absolute;
  top: 25%;
  right: 15%;
  text-align: right;
}

.content h1 {
  max-width: 300px;
  font-family: 'VT323';
  font-size: 4em;
  color: white;
  text-shadow: 4px 4px #ef6461;
}

.sample {
  display: inline-block;
  cursor: pointer;
  padding: 16px 30px;
  margin-left: 20px;
  font-weight: bold;
  text-transform: uppercase;
  color: white;
  background: #888;
  box-shadow: 5px 5px;
  text-align: center;
  border: none;
}

.sample:hover {
  background: white;
  color: #888;
}

@media only screen and (max-width: 768px) {
  .bg {
    background: linear-gradient( rgba(0,0,0,0.4), rgba(0, 0, 0, 0.4) ), url('../../assets/images/home-bg.png');
    background-position: center top;
  }

  .content {
    top: 50%; 
    right: 50%;
    transform: translate(50%,-50%);
    text-align: center;
    width: 100%;
  }

  .content h1 {
    max-width: none;
    width: 80%;
    margin: 0 auto;
  }
}

@media only screen and (max-width: 500px) {
  .content h1 {
    font-size: 3em;
  }
}
