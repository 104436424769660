.controls {
  padding-left: 30px;
  padding-right: 30px;
}

.controls img {
  display: block;
  margin: 0 auto;
  max-width: 100%;
  max-height: 250px;
  border: 1px solid #555;
}

.controls input[type=number] { 
  width: 100%;
  height: 32px;
  font-size: 1em;
  padding: 0px 12px;
}

.export {
  background-color: #ef6461;
  padding: 8px 20px;
  border: none;
  color: white;
  text-transform: uppercase;
  font-weight: bold;
  cursor: pointer;
  width: 100%;
  margin-bottom: 40px;
}

.error {
  color: #EEE;
  font-style: italic;
  text-align: right;
  border-top: 2px solid red;
  padding-top: 4px;
}

.colorCountInput {
  margin-top: 10px;
}

@media only screen and (max-width: 768px) {
  .controls {
    padding-left: 30px;
    padding-right: 30px;
  }
}