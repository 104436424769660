.toolbar {
  height: 64px;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #36c9c6;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 40px;
  z-index: 10;
}

.logo {
  font-family: 'VT323', serif;
  font-size: 38px;
  margin-top: -5px;
  color:white;
  text-decoration: none;
}

.light {
  background-color: white;
  text-shadow: 2px 2px #eee;
  box-shadow: 0 3px 3px -2px rgba(0,0,0,0.2);
}

.light .logo {
  color: #ef6461;
}

.logoLeft {
  width: 20px;
  margin-right: 10px;
}

.logoRight {
  width: 20px;
  margin-left: 8px;
}

@media only screen and (max-width: 768px) {
  .toolbar {
    justify-content: center;
    padding: 0;
  }
}