.loading {
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transform: -webkit-translate(-50%, -50%);
  transform: -moz-translate(-50%, -50%);
  transform: -ms-translate(-50%, -50%);
  z-index: 9999;
}

.text {
  display: inline-block;
  background-color: #ccc;
  padding: 20px 30px;
  min-width: 200px;
  color: black;
  font-weight: bold;
  text-align: center;
  margin: 0 auto;
}