.button {
  background-color: #ef6461;
  border: none;
  border-radius: 5px;
  padding: 5px 10px;
  color: white;
  font-weight: bold;
  text-transform: uppercase;
  cursor: pointer;
}

.primary {
  background-color: #1c9197;
}

.secondary {
  background-color: grey;
}

.small {
  font-size: 0.8em;
}

.label {
  display: flex;
  align-items: center;
}

.label svg {
  margin-right: 5px;
}