.control {
  color: white;
  margin-bottom: 40px;
}

.control .label {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 2px;
  font-weight: bold;
}